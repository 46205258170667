import { mapMutations } from 'vuex'
import { getStorage } from '@/utils/storage'

export const sysMixins = {
  computed: {
    curLeftComponent () {
      return this.$store.state.sys.leftComponent
    },
    curBottomComponent () {
      return this.$store.state.sys.bottomComponent
    },
    curRightComponent () {
      return this.$store.state.sys.rightComponent
    },
    curMapComponent () {
      return this.$store.state.sys.mapComponent
    },
    curBottomHeight () {
      return this.$store.state.sys.bottomHeight
    },
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  },
  methods: {
    ...mapMutations({
      setShowLeftBlock: 'set_show_left_block',
      setShowRightBlock: 'set_show_right_block',
      setShowBottomBlock: 'set_show_bottom_block',
      setShowMap: 'set_show_map',
      setBottomHeight: 'set_bottom_height',
      setLeftComponent: 'set_left_component',
      setRightComponent: 'set_right_component',
      setBottomComponent: 'set_bottom_component',
      setMapComponent: 'set_map_component',
      setLinkTagArray: 'set_link_tag_array',
      setLinkRouterMaps: 'set_link_router_maps',
      setActivedTagName: 'set_actived_tag_name'
    })
  }
}
